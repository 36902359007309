// src/ImageSlider.js
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ImageSlider.css";

const ImageSlider = ({ images }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
  };

  return (
    <div className="image-slider">
      {images.length === 1 ? (
        <div>
          {images.map((image, index) => (
            <div key={index}>
              <img src={image} alt={`Slide ${index}`} />
              <a href={image} download={`image${index}.jpg`}>
                Download
              </a>
            </div>
          ))}
        </div>
      ) : (
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index}>
              <img src={image} alt={`Slide ${index}`} />
              <a href={image} download={`image${index}.jpg`}>
                Download
              </a>
            </div>
          ))}
        </Slider>
      )}
    </div>
  );
};

export default ImageSlider;
