import {
  Grid,
  Typography,
  TextField,
  IconButton,
  Box,
  Paper,
  InputBase,
  Tooltip,
  Chip,
} from "@material-ui/core";
import React, { useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveRoundedIcon from "@material-ui/icons/RemoveRounded";
import { Info } from "@material-ui/icons";

function Top({ dispositionList, webHookData, setWebHookData, type }) {
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  const yesNoFilter = ["yes_no"];
  const dynamicFilter = ["response_data", "dynamic"];
  const rangeFilterLPA = ["ctc_filter"];
  const rangeFilterYears = ["age_filter", "experience_filter"];
  const rangeFilterDays = ["notice_filter"];
  const dispositionFilter = ["disposition_filter"];

  const onRemoveClicked = (data) => {
    const updatedData = webHookData.map((item) =>
      item.order === data.order
        ? { ...item, [type]: null, condition_type: null, selected: false }
        : item
    );
    setWebHookData(updatedData);
  };

  const onAddClicked = () => {
    if (selectedQuestion) {
      const updatedData = webHookData.map((item) =>
        item.order === selectedQuestion.order
          ? { ...item, [type]: null, condition_type: null, selected: true }
          : item
      );

      // setWebHookData(updatedData);

      const reorderedData = [
        ...updatedData.filter(
          (item) => item.selected && item.order === selectedQuestion.order
        ),
        ...updatedData.filter(
          (item) => item.selected && item.order !== selectedQuestion.order
        ),
        ...updatedData.filter((item) => !item.selected),
      ];

      setWebHookData(reorderedData);
      setSelectedQuestion(null);
    }
  };
  const [selectedValues, setSelectedValues] = useState([]);

  const getFilterType = (type_filter) => {
    switch (true) {
      case yesNoFilter.includes(type_filter?.filter_type):
        return (
          <Autocomplete
            id="combo-box-demo"
            options={dataThird}
            disableClearable
            value={type_filter?.[type]}
            getOptionLabel={(option) => option.title}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                label="Choose option"
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  readOnly: true,
                }}
              />
            )}
            onChange={(event, value) => {
              if (value) {
                const updatedData = webHookData.map((data) =>
                  data.order === type_filter?.order
                    ? {
                        ...data,
                        [type]: value,
                      }
                    : data
                );

                setWebHookData(updatedData);
              }
            }}
          />
        );

      case dynamicFilter.includes(type_filter?.filter_type):
        return (
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "space-between",
            }}
          >
            <TextField
              value={type_filter?.[type]?.value || ""}
              variant="outlined"
              label="Enter value"
              onChange={(event) => {
                if (event) {
                  const updatedData = webHookData.map((data) =>
                    data.order === type_filter?.order
                      ? {
                          ...data,
                          [type]: { value: event?.target.value },
                        }
                      : data
                  );

                  setWebHookData(updatedData);
                }
              }}
              fullWidth
            />
            <div style={{ position: "absolute", right: "-25px" }}>
              <Tooltip title="Enter comma separated values" placement="right">
                <IconButton style={{ padding: 0 }}>
                  <Info style={{ fontSize: "16px" }} />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        );

      case rangeFilterLPA.includes(type_filter?.filter_type):
        return (
          <Box>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <Paper
                  component="form"
                  style={{
                    border: "1px solid #C0C0C0",
                    borderRadius: 8,
                    // padding: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    height: 55,
                    boxShadow: "none",
                  }}
                >
                  <Box
                    style={{
                      backgroundColor: "#F2F1F1",
                      paddingLeft: 10,
                      paddingRight: 10,
                      height: "100%",
                      borderTopLeftRadius: 6,
                      borderBottomLeftRadius: 6,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ fontSize: 12, color: "#787878" }}> Min</span>
                  </Box>
                  <InputBase
                    style={{ marginLeft: 10, flex: 1 }}
                    placeholder="LPA"
                    inputProps={{ "aria-label": "LPA" }}
                    value={type_filter?.[type]?.value?.min || ""}
                    onChange={(event) => {
                      if (event) {
                        const updatedData = webHookData.map((data) =>
                          data.order === type_filter?.order
                            ? {
                                ...data,
                                [type]: {
                                  value: {
                                    ...(data?.[type]?.value || {}),
                                    min: event?.target.value,
                                  },
                                },
                              }
                            : data
                        );

                        setWebHookData(updatedData);
                      }
                    }}
                  />
                </Paper>
              </Grid>
              <Grid item md={6}>
                <Paper
                  component="form"
                  style={{
                    border: "1px solid #C0C0C0",
                    borderRadius: 8,
                    // padding: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    height: 55,
                    boxShadow: "none",
                  }}
                >
                  <Box
                    style={{
                      backgroundColor: "#F2F1F1",
                      paddingLeft: 10,
                      paddingRight: 10,
                      height: "100%",
                      borderTopLeftRadius: 6,
                      borderBottomLeftRadius: 6,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ fontSize: 12, color: "#787878" }}> Max</span>
                  </Box>
                  <InputBase
                    style={{ marginLeft: 10, flex: 1 }}
                    placeholder="LPA"
                    inputProps={{ "aria-label": "LPA" }}
                    value={type_filter?.[type]?.value?.max || ""}
                    onChange={(event) => {
                      if (event) {
                        const updatedData = webHookData.map((data) =>
                          data.order === type_filter?.order
                            ? {
                                ...data,
                                [type]: {
                                  value: {
                                    ...(data?.[type]?.value || {}),
                                    max: event?.target.value,
                                  },
                                },
                              }
                            : data
                        );

                        setWebHookData(updatedData);
                      }
                    }}
                  />
                </Paper>
              </Grid>
            </Grid>
          </Box>
        );

      case rangeFilterYears.includes(type_filter?.filter_type):
        return (
          <Box>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <Paper
                  component="form"
                  style={{
                    border: "1px solid #C0C0C0",
                    borderRadius: 8,
                    // padding: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    height: 55,
                    boxShadow: "none",
                  }}
                >
                  <Box
                    style={{
                      backgroundColor: "#F2F1F1",
                      paddingLeft: 10,
                      paddingRight: 10,
                      height: "100%",
                      borderTopLeftRadius: 6,
                      borderBottomLeftRadius: 6,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ fontSize: 12, color: "#787878" }}> Min</span>
                  </Box>
                  <InputBase
                    style={{ marginLeft: 10, flex: 1 }}
                    placeholder="Years"
                    inputProps={{ "aria-label": "Years" }}
                    value={type_filter?.[type]?.value?.min || ""}
                    onChange={(event) => {
                      if (event) {
                        const updatedData = webHookData.map((data) =>
                          data.order === type_filter?.order
                            ? {
                                ...data,
                                [type]: {
                                  value: {
                                    ...(data?.[type]?.value || {}),
                                    min: event?.target.value,
                                  },
                                },
                              }
                            : data
                        );

                        setWebHookData(updatedData);
                      }
                    }}
                  />
                </Paper>
              </Grid>
              <Grid item md={6}>
                <Paper
                  component="form"
                  style={{
                    border: "1px solid #C0C0C0",
                    borderRadius: 8,
                    // padding: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    height: 55,
                    boxShadow: "none",
                  }}
                >
                  <Box
                    style={{
                      backgroundColor: "#F2F1F1",
                      paddingLeft: 10,
                      paddingRight: 10,
                      height: "100%",
                      borderTopLeftRadius: 6,
                      borderBottomLeftRadius: 6,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ fontSize: 12, color: "#787878" }}> Max</span>
                  </Box>
                  <InputBase
                    style={{ marginLeft: 10, flex: 1 }}
                    placeholder="Years"
                    inputProps={{ "aria-label": "Years" }}
                    value={type_filter?.[type]?.value?.max || ""}
                    onChange={(event) => {
                      if (event) {
                        const updatedData = webHookData.map((data) =>
                          data.order === type_filter?.order
                            ? {
                                ...data,
                                [type]: {
                                  value: {
                                    ...(data?.[type]?.value || {}),
                                    max: event?.target.value,
                                  },
                                },
                              }
                            : data
                        );

                        setWebHookData(updatedData);
                      }
                    }}
                  />
                </Paper>
              </Grid>
            </Grid>
          </Box>
        );

      case rangeFilterDays.includes(type_filter?.filter_type):
        return (
          <Box>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <Paper
                  component="form"
                  style={{
                    border: "1px solid #C0C0C0",
                    borderRadius: 8,
                    // padding: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    height: 55,
                    boxShadow: "none",
                  }}
                >
                  <Box
                    style={{
                      backgroundColor: "#F2F1F1",
                      paddingLeft: 10,
                      paddingRight: 10,
                      height: "100%",
                      borderTopLeftRadius: 6,
                      borderBottomLeftRadius: 6,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ fontSize: 12, color: "#787878" }}> Min</span>
                  </Box>
                  <InputBase
                    style={{ marginLeft: 10, flex: 1 }}
                    placeholder="Days"
                    inputProps={{ "aria-label": "Days" }}
                    value={type_filter?.[type]?.value?.min || ""}
                    onChange={(event) => {
                      if (event) {
                        const updatedData = webHookData.map((data) =>
                          data.order === type_filter?.order
                            ? {
                                ...data,
                                [type]: {
                                  value: {
                                    ...(data?.[type]?.value || {}),
                                    min: event?.target.value,
                                  },
                                },
                              }
                            : data
                        );

                        setWebHookData(updatedData);
                      }
                    }}
                  />
                </Paper>
              </Grid>
              <Grid item md={6}>
                <Paper
                  component="form"
                  style={{
                    border: "1px solid #C0C0C0",
                    borderRadius: 8,
                    // padding: "2px 4px",
                    display: "flex",
                    alignItems: "center",
                    height: 55,
                    boxShadow: "none",
                  }}
                >
                  <Box
                    style={{
                      backgroundColor: "#F2F1F1",
                      paddingLeft: 10,
                      paddingRight: 10,
                      height: "100%",
                      borderTopLeftRadius: 6,
                      borderBottomLeftRadius: 6,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ fontSize: 12, color: "#787878" }}> Max</span>
                  </Box>
                  <InputBase
                    style={{ marginLeft: 10, flex: 1 }}
                    placeholder="Days"
                    inputProps={{ "aria-label": "Days" }}
                    value={type_filter?.[type]?.value?.max || ""}
                    onChange={(event) => {
                      if (event) {
                        const updatedData = webHookData.map((data) =>
                          data.order === type_filter?.order
                            ? {
                                ...data,
                                [type]: {
                                  value: {
                                    ...(data?.[type]?.value || {}),
                                    max: event?.target.value,
                                  },
                                },
                              }
                            : data
                        );

                        setWebHookData(updatedData);
                      }
                    }}
                  />
                </Paper>
              </Grid>
            </Grid>
          </Box>
        );

      case dispositionFilter.includes(type_filter):
        return (
          <Autocomplete
            multiple
            id="multi-select-autocomplete"
            options={dispositionList}
            value={
              webHookData?.find((item) => item.filter_type === "disposition")?.[
                type
              ]?.value || []
            }
            onChange={(event, value) => {
              if (value) {
                let updatedData;
                if (
                  webHookData?.some(
                    (item) => item.filter_type === "disposition"
                  )
                ) {
                  updatedData = webHookData.map((data) =>
                    data.filter_type === "disposition"
                      ? {
                          ...data,
                          selected: true,
                          [type]: { value },
                        }
                      : data
                  );
                } else {
                  updatedData = [
                    ...(webHookData || []),
                    {
                      selected: true,
                      key: "disposition",
                      field_name: "Disposition",
                      filter_type: "disposition",
                      [type]: { value },
                    },
                  ];
                }

                setWebHookData(updatedData);
              }
            }}
            disableClearable
            getOptionLabel={(option) => option}
            renderTags={() => null}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Choose options"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        gap: 0.5,
                      }}
                    >
                      {(
                        webHookData?.find(
                          (item) => item.filter_type === "disposition"
                        )?.[type]?.value || []
                      )
                        .slice(0, 2)
                        .map((value) => (
                          <Chip
                            key={value}
                            label={
                              value.length > 15
                                ? `${value.slice(0, 15)}...`
                                : value
                            }
                            size="small"
                          />
                        ))}
                      {(
                        webHookData?.find(
                          (item) => item.filter_type === "disposition"
                        )?.[type]?.value || []
                      ).length > 2 && (
                        <span
                          style={{ fontSize: "14px", marginLeft: "5px" }}
                        >{`+${
                          (
                            webHookData?.find(
                              (item) => item.filter_type === "disposition"
                            )?.[type]?.value || []
                          ).length - 2
                        }`}</span>
                      )}
                    </Box>
                  ),
                }}
              />
            )}
          />
        );

      default:
        return null;
    }
  };

  return (
    <div>
      <Typography
        variant="body1"
        style={{
          fontWeight: "600",
          fontSize: 18,
          marginTop: 10,
          marginBottom: 15,
        }}
      >
        {type === "top_1_val" ? "Best Match" : "Disqualify"}
      </Typography>

      <Grid container spacing={3}>
        <Grid item md={5}>
          <Autocomplete
            id="combo-box-demo"
            value={selectedQuestion}
            options={webHookData}
            getOptionLabel={(option) => option.field_name}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                label="Choose Question"
                variant="outlined"
              />
            )}
            onChange={(event, value) => {
              if (value) {
                setSelectedQuestion(value);
              }
            }}
          />
        </Grid>
        <Grid item md={7}>
          <Box style={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton style={{ color: "#0C81FF" }} onClick={onAddClicked}>
              <AddCircleOutlineIcon />
            </IconButton>
          </Box>
        </Grid>

        {webHookData
          .filter((data) => data.selected && data.filter_type !== "disposition")
          .map((item) => (
            <>
              <Grid item md={5}>
                <TextField
                  value={item?.field_name}
                  label="Question"
                  variant="outlined"
                  disabled
                  fullWidth
                />
              </Grid>
              <Grid item md={3}>
                <Autocomplete
                  id="combo-box-demo"
                  options={
                    dynamicFilter.includes(item?.filter_type)
                      ? dataSecond1
                      : dataSecond2
                  }
                  disableClearable
                  value={item?.condition_type}
                  getOptionLabel={(option) => option.title}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Choose option"
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true,
                      }}
                    />
                  )}
                  onChange={(event, value) => {
                    if (value) {
                      let updatedData;
                      if (value?.value === "mandatory") {
                        updatedData = webHookData.map((data) =>
                          data.order === item.order
                            ? {
                                ...data,
                                condition_type: value,
                                [type]: { value: "any" },
                              }
                            : data
                        );
                      } else {
                        updatedData = webHookData.map((data) =>
                          data.order === item.order
                            ? {
                                ...data,
                                condition_type: value,
                                [type]: null,
                              }
                            : data
                        );
                      }
                      setWebHookData(updatedData);
                    }
                  }}
                />
              </Grid>
              <Grid item md={3}>
                {item?.condition_type?.value === "mandatory"
                  ? null
                  : getFilterType(item)}
              </Grid>
              <Grid item md={1}>
                <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                  <IconButton
                    style={{ color: "#BB1545" }}
                    onClick={() => onRemoveClicked(item)}
                  >
                    <RemoveRoundedIcon />
                  </IconButton>
                </Box>
              </Grid>
            </>
          ))}
      </Grid>
      <Grid container spacing={3}>
        <>
          <Grid item md={2}>
            <TextField
              value={"Disposition"}
              variant="outlined"
              disabled
              fullWidth
            />
          </Grid>
          <Grid item md={4}>
            <Autocomplete
              id="combo-box-demo"
              options={dataSecond3}
              disableClearable
              value={
                webHookData?.find((item) => item.filter_type === "disposition")
                  ?.condition_type || ""
              }
              getOptionLabel={(option) => option.title}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose option"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    readOnly: true,
                  }}
                />
              )}
              onChange={(event, value) => {
                let updatedData;
                if (
                  webHookData?.some(
                    (item) => item.filter_type === "disposition"
                  )
                ) {
                  updatedData = webHookData.map((data) =>
                    data.filter_type === "disposition"
                      ? {
                          ...data,
                          selected: true,
                          condition_type: value,
                          [type]: null,
                        }
                      : data
                  );
                } else {
                  updatedData = [
                    ...(webHookData || []),
                    {
                      selected: true,
                      key: "disposition",
                      field_name: "Disposition",
                      filter_type: "disposition",
                      condition_type: value,
                      [type]: null,
                    },
                  ];
                }

                setWebHookData(updatedData);
              }}
            />
          </Grid>
          <Grid item md={6}>
            {getFilterType("disposition_filter")}
          </Grid>
        </>
      </Grid>
    </div>
  );
}

export default Top;

const dataSecond1 = [
  { title: "Any Value", value: "mandatory" },
  { title: "Condition matched", value: "in" },
  { title: "Condition not matched", value: "nin" },
];
const dataSecond2 = [
  { title: "Any Value", value: "mandatory" },
  { title: "Condition matched", value: "in" },
];
const dataSecond3 = [
  { title: "Condition matched", value: "in" },
  { title: "Condition not matched", value: "nin" },
];

const dataThird = [
  { title: "Yes", value: "yes" },
  { title: "No", value: "no" },
];
