import React from "react";
import { Route, Switch } from "react-router-dom";
import AddDegreeSpecialization from "../components/Branch_Courses/AddDegreeSpecialization";
import EditDegrees from "../components/Branch_Courses/EditDegrees";
import EditSpecializations from "../components/Branch_Courses/EditSpecializations";
import AccountManagerCollege from "../components/College/AccountManagerCollege";
import AddCollege from "../components/College/AddCollege";
import AddCollegesExcel from "../components/College/AddCollegesExcel";
import AddNewCollegeStepper from "../components/College/AddNewCollegeStepper";
import AllColleges from "../components/College/AllColleges";
import AllYear from "../components/College/AllYear";
import Applicants from "../components/College/Applicants";
import CollegeSpecializations from "../components/College/CollegeSpecializations";
import EditCollegeDetails from "../components/College/EditCollegeDetails";
import FinalYear from "../components/College/FinalYear";
import HiredStudents from "../components/College/HiredStudents";
import CollegeInboundLeads from "../components/College/InboundLeads";
import JobsPerCollege from "../components/College/JobsPerCollege";
import JobsSent from "../components/College/JobsSent";
import ManageCandidates from "../components/College/ManageCandidates";
import PaymentDetails from "../components/College/PaymentDetails";
import StudentsPerCollege from "../components/College/StudentsPerCollege";
import UserManagement from "../components/common/UserManagement";
import AccountManagerCompany from "../components/Company/AccountManagerCompany";
import AddCompaniesExcel from "../components/Company/AddCompaniesExcel";
import AddCompany from "../components/Company/AddCompany";
import Aicredit from "../components/Company/AiCredits";
import AddNewCompany from "../components/Company/AddNewCompany";
import AllCompanies from "../components/Company/AllCompanies";
import BookSlot from "../components/Company/BookSlot";
import CompanyLeads from "../components/Company/CompanyLeads";
import CompanyProposalLinks from "../components/Company/CompanyProposalLinks";
import EditCompanyDetails from "../components/Company/EditCompanyDetails";
import EditNewCompany from "../components/Company/EditNewCompany";
import VerifyCompany from "../components/Company/VerifyCompany";
import Dashboard from "../components/Dashboard/Dashboard";
import ManagerDashboard from "../components/Dashboard/ManagerDashboard";
import TotalHiredStudents from "../components/Dashboard/TotalHiredStudents";
import AddGroups from "../components/EducationalGroup/AddGroups";
import AllGroups from "../components/EducationalGroup/AllGroups";
import CollegeList from "../components/EducationalGroup/CollegeList";
import EditGroup from "../components/EducationalGroup/EditGroup";
import AddCompanyClub from "../components/GetworkClub/AddCompany";
import AllClubCategory from "../components/GetworkClub/AllClubCategory";
import AllClubSubCategory from "../components/GetworkClub/AllClubSubCategory";
import AllCoupan from "../components/GetworkClub/AllCoupan";
import AllCourse from "../components/GetworkClub/AllCourse";
import AllGetworkClubService from "../components/GetworkClub/AllGetworkClubService";
import AllRewards from "../components/GetworkClub/AllRewards";
import AllServices from "../components/GetworkClub/AllServices";
import AllVendors from "../components/GetworkClub/AllVendors";
import ClubSegment from "../components/GetworkClub/ClubSegment";
import ELearnings from "../components/GetworkClub/ELearnings";
import GetworkClubCourse from "../components/GetworkClub/GetworkClubCourse";
import OrderHistory from "../components/GetworkClub/OrderHistory";
import ProductBanner from "../components/GetworkClub/ProductBanner";
import ProductCoupons from "../components/GetworkClub/ProductCoupons";
import TeamGetwork from "../components/GetworkClub/TeamGetwork";
import AddCredits from "../components/GetWork_Credits/AddCredits";
import ProfileUnlock from "../components/GetWork_Credits/ProfileUnlock";
import UserManualPayment from "../components/GetWork_Credits/UserManualPayment";
import PostJobThirdParty from "../components/Jobs/3_party_jobs/JobPosting";
import ApplyJob from "../components/Jobs/ApplyJob";
import CloneJob from "../components/Jobs/CloneJob";
import CloseJobs from "../components/Jobs/CloseJobs";
import CloseRequestJobs from "../components/Jobs/CloseRequest";
import EditJob from "../components/Jobs/EditJob";
import JobTrack from "../components/Jobs/JobTrack";
import NewJobTrack from "../components/Jobs/NewJobTrack";
import OpenJobs from "../components/Jobs/OpenJobs";
import PostJob from "../components/Jobs/PostJob";
import PostPublicJob from "../components/Jobs/PostPublicJob";
import PublicJobPosting from "../components/Jobs/PublicJobPosting";
import SalesJobResponse from "../components/Jobs/SalesJobResponse";
import ThirdPartyJobsPosting from "../components/Jobs/ThirdPartyJobs";
import VerifyJobs from "../components/Jobs/VerifyJobs";
import ActiveYears from "../components/Miscellaneous/ActiveYears";
import ChangePassword from "../components/Miscellaneous/ChangePassword";
import CompanyCoworking from "../components/Miscellaneous/CompanyCoworking";
import CompanyDomain from "../components/Miscellaneous/CompanyDomain";
import ContactUsResponse from "../components/Miscellaneous/ContactUsResponse";
import EducationSkills from "../components/Miscellaneous/EducationSkills";
import Industry from "../components/Miscellaneous/Industry";
import JobDescription from "../components/Miscellaneous/JobDescription";
import JobEmployeeType from "../components/Miscellaneous/JobEmployeeType";
import JobRole from "../components/Miscellaneous/JobRole";
import JobRoleGroup from "../components/Miscellaneous/JobRoleGroup";
import JobRound from "../components/Miscellaneous/JobRound";
import JobSegment from "../components/Miscellaneous/JobSegment";
import JobStatus from "../components/Miscellaneous/JobStatus";
import JobType from "../components/Miscellaneous/JobType";
import Location from "../components/Miscellaneous/Location";
import PageType from "../components/Miscellaneous/PageType";
import PaidServicesCredit from "../components/Miscellaneous/PaidServicesCredit";
import ReferralCode from "../components/Miscellaneous/ReferralCode";
import SharerCategory from "../components/Miscellaneous/SharerCategory";
import SharerSubCategory from "../components/Miscellaneous/SharerSubCategory";
import SharerSubCategoryPost from "../components/Miscellaneous/SharerSubCategoryPost";
import SingleBlog from "../components/Miscellaneous/SingleBlog";
import SkillRating from "../components/Miscellaneous/SkillRating";
import SkillType from "../components/Miscellaneous/SkillType";
import University from "../components/Miscellaneous/University";
import UpdateBanner from "../components/Miscellaneous/UpdateBanner";
import UpdateBlog from "../components/Miscellaneous/UpdateBlog";
import UploadImages from "../components/Miscellaneous/UploadImages";
import UploadRecharges from "../components/Miscellaneous/UploadRecharges";
import UserDetails from "../components/Miscellaneous/UserDetails";
import PushNotifications from "../components/Notifications/PushNotifications";
import AccessControls from "../components/Saas/AccessControls";
import PlacementRules from "../components/Saas/PlacementRules";
import AllResume from "../components/Student/AllResume";
import AllStudents from "../components/Student/AllStudents";
import AllStudentsNew from "../components/Student/AllStudentsNew";
import NewUploadStudentApplyExcel from "../components/Student/NewUploadStudentApplyExcel";
import SearchStudentExcel from "../components/Student/SearchStudentExcel";
import StudentResdex from "../components/Student/StudentResdex";
import StudentsDetails from "../components/Student/StudentsDetails";
import UploadStudentExcel from "../components/Student/UploadStudentExcel";
import CategoryVault from "../components/Vault/Category";
import SubCategoryVault from "../components/Vault/SubCategory";
import CompanyPayment from "./Company/CompanyPayment";
import ManagePlans from "./Company/ManagePlans";
import MySubscriptions from "./Company/MySubscriptions";
import PlanUnlockHistory from "./Company/PlanUnlockHistory";
import SlotDetail from "./Company/SlotDetail";
import BulkCreate from "./Marketing/BulkCreate";
import CandidateCompanyLeads from "./Marketing/CandidateCompanyLeads";
import MarketingLeads from "./Marketing/MarketingLeads";
import NotFound from "./NotFound";
import Reports from "./Reports";
import CompanyWiseReport from "./Reports/CompanyWiseReport";
import UTMReports from "./Reports/UTMReports";
import EditTemplate from "./Sandesh/EditTemplate";
import ViewTemplate from "./Sandesh/ViewTemplate";
import ResumeTemplates from "./Students/ResumeTemplates";
import ResumeUsers from "./Students/ResumeUsers";
import WelcomePage from "./WelcomePage";
import AddVendor from "../components/Vendor/AddVendor";
import AllVendor from "../components/Vendor/AllVendor";
import VendorRaisedRequest from "../components/Vendor/VendorRaisedRequest";
import VendorJob from "../components/Vendor/VendorJob";
import FirmReports from "./Reports/FirmReport";
import StudentsHistory from "../components/Student/StudentsHistory";
import AllInterviews from "./Jobs/allInterviews";
import Subscribers from "../components/Vendor/Subscribers";
import AssignCandidates from "../components/Student/AssignCandidates";
import AiCallingBot from "../components/Jobs/AiCallingBot";
import RecruiterReport from "./RecruiterReport/RecruiterReport";
import NewDashboard from "./Dashboard/NewDashboard";
import TeamLeadDashboard from "./TeamLeadDashboard/TeamLeadDashboard";
import ManagerTeamLeadDashboard from "./ManagerTeamLeadDashboard/ManagerTeamLeadDashboard";
import AicreditsCollege from "../components/College/AiCreditsCollege";
import DownloadReport from "./DownloadReport/DownloadReport";
import HelpDesk from "./HelpDesk";

function MainContent() {
  return (
    <div style={{ minWidth: "400px" }}>
      {/* <Router> */}
      <Switch>
        <Route exact path="/admin/welcome_to_getwork">
          <WelcomePage />
        </Route>
        <Route exact path="/admin">
          <Dashboard />
        </Route>
        <Route exact path="/admin/source-candidate">
          <ManageCandidates />
        </Route>
        <Route exact path="/admin/dashboard">
          <Dashboard />
        </Route>

        <Route exact path="/admin/manager-dashboard">
          <ManagerDashboard />
        </Route>

        <Route exact path="/admin/add-college">
          <AddCollege />
        </Route>
        <Route exact path="/admin/add-new-college">
          <AddNewCollegeStepper />
        </Route>
        {/*   <Route exact path="/admin/add-new-college-stepper">
            <AddNewCollegeStepper />
          </Route> */}
        <Route exact path="/admin/add-group">
          <AddGroups />
        </Route>
        <Route exact path="/admin/all-groups">
          <AllGroups />
        </Route>
        <Route exact path="/admin/edit-group">
          <EditGroup />
        </Route>
        <Route path="/admin/group/college-list/:group_id">
          <CollegeList />
        </Route>
        <Route exact path="/admin/contact-us-response">
          <ContactUsResponse />
        </Route>
        <Route exact path="/admin/marketing-leads">
          <MarketingLeads />
        </Route>
        <Route exact path="/admin/candidate-companies-leads">
          <CandidateCompanyLeads />
        </Route>
        <Route exact path="/admin/bulk-create">
          <BulkCreate />
        </Route>

        <Route exact path="/admin/college-inbound-leads">
          <CollegeInboundLeads />
        </Route>
        <Route exact path="/admin/edit-college">
          <EditCollegeDetails />
        </Route>
        <Route exact path="/admin/payment-details">
          <PaymentDetails />
        </Route>
        <Route exact path="/admin/account-manager-college">
          <AccountManagerCollege />
        </Route>
        <Route exact path="/admin/all-colleges">
          <AllColleges />
        </Route>
        <Route exact path="/admin/all-colleges/user-created">
          <AllColleges userCreated={true} />
        </Route>
        <Route exact path="/admin/students-per-college">
          <StudentsPerCollege />
        </Route>
        <Route exact path="/admin/jobs-per-college">
          <JobsPerCollege />
        </Route>
        <Route exact path="/admin/upload-college-excel">
          <AddCollegesExcel />
        </Route>
        <Route exact path="/admin/all-companies">
          <AllCompanies />
        </Route>
        <Route exact path="/admin/add-company">
          <AddCompany />
        </Route>
        <Route exact path="/admin/manage-ai-credits">
          <Aicredit />
        </Route>
        <Route exact path="/admin/manage-college-ai-credits">
          <AicreditsCollege />
        </Route>
        <Route exact path="/admin/edit-company">
          <EditCompanyDetails />
        </Route>
        <Route exact path="/admin/verify-company">
          <VerifyCompany />
        </Route>
        <Route exact path="/admin/company-leads">
          <CompanyLeads />
        </Route>
        <Route exact path="/admin/account-manager-company">
          <AccountManagerCompany />
        </Route>
        <Route exact path="/admin/company-proposal-links">
          <CompanyProposalLinks />
        </Route>
        <Route exact path="/admin/upload-company-excel">
          <AddCompaniesExcel />
        </Route>
        <Route exact path="/admin/add-new-company">
          <AddNewCompany />
        </Route>
        <Route exact path="/admin/new-edit-company">
          <EditNewCompany />
        </Route>
        <Route exact path="/admin/company-payments">
          <CompanyPayment />
        </Route>
        <Route exact path="/admin/manage-plans">
          <ManagePlans />
        </Route>
        <Route exact path="/admin/new-dashboard">
          <NewDashboard />
        </Route>
        <Route exact path="/admin/recruiter-report">
          <RecruiterReport />
        </Route>
        <Route exact path="/admin/team-lead">
          <TeamLeadDashboard />
        </Route>
        <Route exact path="/admin/manager-team-lead">
          <ManagerTeamLeadDashboard />
        </Route>
        <Route exact path="/admin/plan-unlock-history">
          <PlanUnlockHistory />
        </Route>
        <Route exact path="/admin/active-plan/:company_name/:company_id">
          <MySubscriptions />
        </Route>
        <Route exact path="/admin/active-slot/:company_name/:company_id">
          <SlotDetail />
        </Route>
        <Route exact path="/admin/single-student-details">
          <AllStudents />
        </Route>
        <Route exact path="/admin/student-details/">
          <StudentsDetails />
        </Route>
        <Route exact path="/admin/assign-candidate/">
          <AssignCandidates />
        </Route>
        <Route exact path="/admin/student-history">
          <StudentsHistory />
        </Route>
        <Route exact path="/admin/all-students">
          <AllStudentsNew />
        </Route>
        <Route exact path="/admin/upload-student-excel">
          <UploadStudentExcel />
        </Route>
        <Route exact path="/admin/resume-templates">
          <ResumeTemplates />
        </Route>
        <Route exact path="/admin/user-resume">
          <ResumeUsers />
        </Route>

        <Route exact path="/admin/push-notifications">
          <PushNotifications />
        </Route>

        <Route exact path="/admin/add-new-degree-specialization">
          <AddDegreeSpecialization />
        </Route>
        <Route exact path="/admin/edit-degrees">
          <EditDegrees />
        </Route>
        <Route exact path="/admin/edit-specializations">
          <EditSpecializations />
        </Route>

        <Route exact path="/admin/change-password">
          <ChangePassword />
        </Route>
        <Route exact path="/admin/update-blog">
          <UpdateBlog />
        </Route>
        <Route exact path="/admin/view-blog/:blog_id">
          <SingleBlog />
        </Route>
        <Route exact path="/admin/update-banner">
          <UpdateBanner />
        </Route>
        <Route exact path="/admin/sharer-category">
          <SharerCategory />
        </Route>
        <Route exact path="/admin/sharer-sub-category">
          <SharerSubCategory />
        </Route>
        <Route exact path="/admin/sharer-sub-category-post">
          <SharerSubCategoryPost />
        </Route>
        <Route exact path="/admin/active-years">
          <ActiveYears />
        </Route>
        <Route exact path="/admin/page-type">
          <PageType />
        </Route>
        <Route exact path="/admin/education-skills">
          <EducationSkills />
        </Route>
        <Route exact path="/admin/company-domain">
          <CompanyDomain />
        </Route>
        <Route exact path="/admin/company-industry">
          <Industry />
        </Route>
        <Route exact path="/admin/university">
          <University />
        </Route>
        <Route exact path="/admin/paid-services-credit">
          <PaidServicesCredit />
        </Route>
        <Route exact path="/admin/company-coworking">
          <CompanyCoworking />
        </Route>
        <Route exact path="/admin/job-round">
          <JobRound />
        </Route>
        <Route exact path="/admin/job-status">
          <JobStatus />
        </Route>
        <Route exact path="/admin/job-type">
          <JobType />
        </Route>
        <Route exact path="/admin/job-segment">
          <JobSegment />
        </Route>
        <Route exact path="/admin/job-role">
          <JobRole />
        </Route>
        <Route exact path="/admin/upload-images">
          <UploadImages />
        </Route>
        <Route exact path="/admin/upload-recharges">
          <UploadRecharges />
        </Route>

        <Route exact path="/admin/job-role-group">
          <JobRoleGroup />
        </Route>
        <Route exact path="/admin/job-emp-type">
          <JobEmployeeType />
        </Route>
        <Route exact path="/admin/message-template">
          <ViewTemplate />
        </Route>
        <Route exact path="/admin/edit-sandesh-template">
          <EditTemplate />
        </Route>
        <Route exact path="/admin/location">
          <Location />
        </Route>
        <Route exact path="/admin/skill-type">
          <SkillType />
        </Route>
        <Route exact path="/admin/skill-rating">
          <SkillRating />
        </Route>
        <Route exact path="/admin/referral-code">
          <ReferralCode />
        </Route>
        <Route exact path="/admin/daily-reports">
          <DownloadReport />
        </Route>
        <Route exact path="/admin/college/specializations/:clg_id">
          <CollegeSpecializations />
        </Route>
        <Route exact path="/admin/college/final-year/:clg_id">
          <FinalYear />
        </Route>
        <Route exact path="/admin/college/all-students/:clg_id">
          <AllYear />
        </Route>
        <Route exact path="/admin/college/hired-students/:clg_id">
          <HiredStudents />
        </Route>
        <Route exact path="/admin/college/jobs-sent/:clg_id">
          <JobsSent />
        </Route>
        <Route exact path="/admin/college/applicants/:clg_id/:job_id">
          <Applicants />
        </Route>
        <Route exact path="/admin/college-user-management">
          <UserManagement isCompany={false} />
        </Route>

        <Route exact path="/admin/add-getwork-credits">
          <AddCredits />
        </Route>
        <Route exact path="/admin/user-manual-payment">
          <UserManualPayment />
        </Route>
        <Route exact path="/admin/unlock-credits">
          <ProfileUnlock />
        </Route>
        <Route exact path="/admin/job-track/:job_id/:comp_id">
          <NewJobTrack />
        </Route>
        <Route exact path="/admin/open-jobs">
          <OpenJobs />
        </Route>
        <Route exact path="/admin/ai-calling-bot/:job_id/:job_title">
          <AiCallingBot />
        </Route>
        <Route exact path="/admin/all-interviews">
          <AllInterviews />
        </Route>
        <Route exact path="/admin/open-jobs/off-campus">
          <OpenJobs offCampus={true} />
        </Route>
        <Route exact path="/admin/close-jobs">
          <CloseJobs />
        </Route>
        <Route exact path="/admin/job-close-request/">
          <CloseRequestJobs />
        </Route>
        <Route exact path="/admin/post-job">
          <PostJob />
        </Route>
        <Route exact path="/admin/sales-job-posting">
          <SalesJobResponse />
        </Route>
        <Route exact path="/admin/verify-jobs">
          <VerifyJobs />
        </Route>
        <Route exact path="/admin/3rd-party-jobs">
          <ThirdPartyJobsPosting />
        </Route>
        <Route exact path="/admin/3rd-party-job-posting/:job_id/:comp_id">
          <PostJobThirdParty />
        </Route>
        <Route exact path="/admin/edit-job">
          <EditJob />
        </Route>
        <Route exact path="/admin/clone-job">
          <CloneJob />
        </Route>
        <Route exact path="/admin/apply-student-to-job">
          <ApplyJob />
        </Route>
        <Route exact path="/admin/public-job-posting/:job_id/:comp_id/">
          <PostPublicJob />
        </Route>
        <Route exact path="/admin/public-jobs">
          <PublicJobPosting />
        </Route>

        <Route exact path="/admin/upload-student-job-apply-excel">
          {/* <UploadStudentJobApplyExcel /> */}
          <NewUploadStudentApplyExcel />
        </Route>
        <Route exact path="/admin/3rd-party-search">
          <SearchStudentExcel />
        </Route>
        <Route exact path="/admin/company-user-management">
          <UserManagement isCompany={true} />
        </Route>

        <Route exact path="/admin/hired-students">
          <TotalHiredStudents />
        </Route>
        <Route exact path="/admin/user-details">
          <UserDetails />
        </Route>
        <Route exact path="/admin/job-description">
          <JobDescription />
        </Route>
        {/* <Route exact path="/admin/source-candidate">
          <SourceCandidate />
        </Route> */}
        <Route exact path="/admin/student-resdex">
          <StudentResdex />
        </Route>
        <Route exact path="/admin/access-controls">
          <AccessControls />
        </Route>
        <Route exact path="/admin/placement-rules">
          <PlacementRules />
        </Route>
        <Route exact path="/admin/all-services/:product_id?">
          <AllServices />
        </Route>
        <Route exact path="/admin/category-vault">
          <CategoryVault />
        </Route>
        <Route exact path="/admin/sub-category-vault">
          <SubCategoryVault />
        </Route>
        {/* <Route exact path="/admin/bulk-resume">
          <BulkResume />
        </Route> */}

        <Route exact path="/admin/all-resume">
          <AllResume />
        </Route>
        <Route exact path="/admin/team-getwork">
          <TeamGetwork />
        </Route>
        <Route exact path="/admin/operations-reports">
          <Reports />
        </Route>
        <Route exact path="/admin/service-desk">
          <HelpDesk />
        </Route>

        <Route exact path="/admin/company-reports">
          <CompanyWiseReport />
        </Route>

        <Route exact path="/admin/firm-reports">
          <FirmReports />
        </Route>

        <Route exact path="/admin/utm-reports">
          <UTMReports />
        </Route>

        <>
          <Route exact path="/admin/club-category">
            <AllClubCategory />
          </Route>
          <Route exact path="/admin/club-sub-category">
            <AllClubSubCategory />
          </Route>
          <Route exact path="/admin/add-club-companies/:vendor_id?">
            <AddCompanyClub />
          </Route>
          <Route exact path="/admin/all-vendors">
            <AllVendors />
          </Route>
          <Route exact path="/admin/all-getworkclub-services">
            <AllGetworkClubService />
          </Route>
          <Route exact path="/admin/all-getworkclub-course">
            <GetworkClubCourse />
          </Route>
          <Route exact path="/admin/product-coupons">
            <ProductCoupons />
          </Route>
          <Route exact path="/admin/all-coupan">
            <AllCoupan />
          </Route>
          <Route exact path="/admin/edit-e-learning">
            <ELearnings />
          </Route>
          <Route exact path="/admin/all-getworkclub-course-from">
            <AllCourse />
          </Route>
          <Route exact path="/admin/add-coupons/:coupon_id?">
            <AllRewards />
          </Route>
          <Route exact path="/admin/add-product-banner">
            <ProductBanner />
          </Route>
          <Route exact path="/admin/order-history">
            <OrderHistory />
          </Route>
          <Route exact path="/admin/segments">
            <ClubSegment />
          </Route>
          <Route exact path="/admin/book-slots">
            <BookSlot />
          </Route>
          <Route exact path="/admin/add-vendor-partners">
            <AddVendor />
          </Route>
          <Route exact path="/admin/view-vendor-partners">
            <AllVendor />
          </Route>
          <Route exact path="/admin/vendor-withdrawl-request">
            <VendorRaisedRequest />
          </Route>
          <Route exact path="/admin/vendor-partners-jobs">
            <VendorJob />
          </Route>
          <Route exact path="/admin/podcast-subscribers">
            <Subscribers />
          </Route>
        </>

        <Route exact path="*">
          <NotFound />
        </Route>
      </Switch>
      {/* </Router> */}
    </div>
  );
}

export default MainContent;
