import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import moment from "moment";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  withStyles,
  TableContainer,
  Paper,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  table: {
    // minWidth: 700,
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function ActionHistoryDialog({ actionData }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button variant="text" color="primary" onClick={handleClickOpen}>
        Action
      </Button>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">Action Hisotry </DialogTitle>
        <DialogContent>
          <Box>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>User ID</StyledTableCell>
                    <StyledTableCell align="left">User Name</StyledTableCell>
                    <StyledTableCell align="left">Action</StyledTableCell>
                    <StyledTableCell align="left">Update Time</StyledTableCell>
                    <StyledTableCell align="left">comment</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {actionData.map((item, id) => (
                    <StyledTableRow key={id}>
                      <StyledTableCell align="Left">
                        {item?.user_id}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {item?.user_name}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {item?.action}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {item?.updated_time
                          ? moment(item?.updated_time).format("DD/MM/YYYY")
                          : ""}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {item?.comment ? item?.comment : "NA"}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
