import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  TextField,
  Typography,
  NativeSelect,
  FormControl,
  CircularProgress,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Clear } from "@material-ui/icons";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
// import { apiCall } from "../../../util/apiCall";
// import { FailureAlert } from "../../../util/Notifications";
// import { requestPraveshOptions } from "../../../constants";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  chipsection: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    paddingLeft: "0px",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
    marginLeft: 0,
  },
  comboOptions: {
    fontSize: "13px",
    padding: "10px 11px",
  },
  fieldStyling: {
    width: "100%",
    // marginBottom: 20,
  },
  fieldStyling1: {
    width: "100%",
    marginBottom: 10,
  },
}));

export default function ReportFilter(props) {
  const classes = useStyles();
  const fullWidth = true;
  const maxWidth = "xs";

  const handleClose = () => {
    props.handleClose();
  };

  const checkValidation = () => {
    return true;
  };

  // useEffect(() => {
  //   apiCall("/api/admin/college/get/", requestPraveshOptions).then((res) => {
  //     if (res.success) {
  //       let collegeNames = {};
  //       for (var i = 0; i < res.data.length; i++) {
  //         let obj = res.data[i];
  //         collegeNames[obj.college_id] = obj.college_name;
  //       }
  //       setColleges(res.data);
  //       localStorage.setItem("colleges", JSON.stringify(collegeNames));
  //     } else {
  //       FailureAlert(res.error);
  //     }
  //   });
  // }, []);

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 500,
  });

  return (
    <React.Fragment>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={props?.open}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent>
          <Typography variant="h6">Filters</Typography>

          <div style={{ marginTop: "10px", marginBottom: "20px" }}>
            <Autocomplete
              id="select-user"
              options={props?.colleges}
              filterOptions={filterOptions}
              getOptionLabel={(item) => item?.college_name}
              onChange={(e, item) =>
                item
                  ? props?.setFilters({
                      ...props?.filters,
                      selectCollegeId: item.college_id,
                    })
                  : props?.setFilters({
                      ...props?.filters,
                      selectCollegeId: null,
                    })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select College"
                  variant="outlined"
                />
              )}
            />
          </div>

          <div
            style={{ marginTop: "10px", marginBottom: "20px", width: "100%" }}
          >
            <FormControl style={{ width: "100%" }}>
              <span style={{ fontSize: "12px" }}>Select Satus</span>
              <NativeSelect
                value={props?.filters?.selectedSortBy}
                onChange={(event) =>
                  props?.setFilters({
                    ...props?.filters,
                    selectedSortBy: event.target.value,
                  })
                }
                inputProps={{
                  name: "name",
                  id: "uncontrolled-native",
                }}
                endAdornment={
                  props?.filters?.selectedSortBy && (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={(event) =>
                          props?.setFilters({
                            ...props?.filters,
                            selectedSortBy: "",
                          })
                        }
                      >
                        <Clear />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              >
                <option value={""}></option>
                <option value={"in_progress"}>In Progress</option>
                <option value={"created"}>Created</option>
                <option value={"resolved"}>Resolved</option>
                <option value={"closed"}>Close</option>
              </NativeSelect>
            </FormControl>
          </div>

          <Grid container spacing={2} style={{ marginBottom: "10px" }}>
            <Grid item xs={6} style={{ display: "grid" }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClose}
                style={{ height: "45px", display: "flex" }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6} style={{ display: "grid" }}>
              <Button
                variant="contained"
                color="primary"
                style={{
                  height: "45px",
                  display: "flex",
                  color: "#fff",
                  boxShadow: "none",
                }}
                onClick={() => {
                  if (checkValidation()) {
                    // props?.setFilters({
                    //   ...props?.filters,
                    //   selectedCollegeList: selectedCollegeList,
                    //   selectedSortBy: selectedSortBy,
                    // });
                    props?.getServiceList();
                    // props?.handleClose();
                  }
                }}
              >
                {props?.loader ? (
                  <CircularProgress color="inherit" size={18} />
                ) : (
                  <span style={{ color: "#fff" }}>Apply</span>
                )}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
