import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  Box,
  Grid,
  TextField,
  FormControl,
  NativeSelect,
  CircularProgress,
  Typography,
} from "@material-ui/core";

export default function StatusDialog({
  status,
  handleClose,
  handleClickOpen,
  open,
  updateRow,
  currentRow,
  statusUpdate,
  setStatusUpdate,
  curentStatus,
  loading,
}) {
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("xs");

  return (
    <React.Fragment>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">Change Status</DialogTitle>
        <DialogContent>
          <Box>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <Typography variant="body1" style={{ fontWeight: "600" }}>
                  Currrent Status:
                </Typography>
              </Grid>
              <Grid item md={6}>
                <Typography variant="body2">{curentStatus}</Typography>
              </Grid>
              <Grid
                item
                md={6}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography variant="body1" style={{ fontWeight: "600" }}>
                  New Status:
                </Typography>
              </Grid>
              <Grid item md={6}>
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <FormControl style={{ width: "100%" }}>
                    <span style={{ fontSize: "12px" }}>Select Satus</span>
                    <NativeSelect
                      defaultValue={curentStatus}
                      onClick={(e) => {
                        setStatusUpdate({
                          ...statusUpdate,
                          new_status: e.target.value,
                        });
                      }}
                      inputProps={{
                        name: "name",
                        id: "uncontrolled-native",
                      }}
                    >
                      {curentStatus === "CREATED" ? (
                        <>
                          <option value={""}></option>
                          <option value={"IN_PROGRESS"}>In Progress</option>
                          <option value={"RESOLVED"}>Resolved</option>
                          <option value={"CLOSED"}>Close</option>
                        </>
                      ) : (
                        ""
                      )}
                      {curentStatus === "IN_PROGRESS" ? (
                        <>
                          <option value={""}></option>
                          <option value={"RESOLVED"}>Resolved</option>
                          <option value={"CLOSED"}>Close</option>
                        </>
                      ) : (
                        ""
                      )}
                      {curentStatus === "RESOLVED" ? (
                        <>
                          <option value={""}></option>
                          <option value={"IN_PROGRESS"}>In Progress</option>
                          <option value={"CLOSED"}>Close</option>
                        </>
                      ) : (
                        ""
                      )}
                    </NativeSelect>
                  </FormControl>
                </div>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  rows={3}
                  fullWidth
                  required
                  onChange={(e) => {
                    setStatusUpdate({
                      ...statusUpdate,
                      comment: e.target.value,
                    });
                  }}
                  multiline
                  label="comments"
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              updateRow(currentRow?.id);
            }}
            style={{
              height: "45px",
              display: "flex",
              boxShadow: "none",
              color: "#fff",
            }}
          >
            {loading ? <CircularProgress color="inherit" size={18} /> : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
